/* Font Definition*/
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Thin.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-ExtraLight.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Light.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Regular.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Medium.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-SemiBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Bold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 800;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-ExtraBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.vash.network/fonts/NotoSansTC/NotoSansTC-Black.woff2")
    format("woff2");
  font-display: swap;
}

/* Font Definition*/
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Thin.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-ExtraLight.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Light.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Regular.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Medium.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-SemiBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Bold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 800;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-ExtraBold.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.vash.network/fonts/NotoSansJP/NotoSansJP-Black.woff2")
    format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Bungee";
  font-style: normal;
  src: url("https://cdn.vash.network/fonts/Bungee/Bungee-Regular.woff2")
    format("woff2");
  font-display: swap;
}

body {
  font-family: "Noto Sans TC", "Noto Sans JP", sans-serif !important;
}

.logo {
  font-family: "Bungee", sans-serif;
}

.firebase-emulator-warning {
  display: none !important;
}

.chakra-stack {
  gap: 0 !important;
}

/* 使所有元素的滾動條軌道變透明 */
#sidebar::-webkit-scrollbar,
#sidebar-drawer::-webkit-scrollbar {
  width: 4px;
}
/* 定制滾動條控件的樣式 */
#sidebar::-webkit-scrollbar-thumb,
#sidebar-drawer::-webkit-scrollbar-thumb {
  background-color: #eee; /* 這裡可以自定義顏色 */
  border: 2px solid transparent; /* 可選，添加邊框（這裡設為透明） */
  cursor: pointer;
}

/* 當滾動條控件懸停時的樣式 */
#sidebar::-webkit-scrollbar-thumb:hover,
#sidebar-drawer::-webkit-scrollbar-thumb:hover {
  background-color: #ccc; /* 懸停時的顏色，可自定義 */
  cursor: pointer;
}

/* 使所有元素的滾動條軌道變透明 */
body::-webkit-scrollbar {
  width: 4px;
}
/* 定制滾動條控件的樣式 */
body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: var(--chakra-colors-orange-500); /* 這裡可以自定義顏色 */
  border: 2px solid transparent; /* 可選，添加邊框（這裡設為透明） */
  cursor: pointer;
}

/* 當滾動條控件懸停時的樣式 */
body::-webkit-scrollbar-thumb:hover {
  background-color: var(
    --chakra-colors-orange-400
  ); /* 懸停時的顏色，可自定義 */
  cursor: pointer;
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.markdown {
  h1 {
    font-size: var(--chakra-fontSizes-3xl);
    font-weight: 900;
    margin-top: var(--chakra-space-4);
  }
  h2 {
    font-size: var(--chakra-fontSizes-3xl);
    font-weight: 700;
    margin-top: var(--chakra-space-4);
  }
  h3 {
    font-size: var(--chakra-fontSizes-2xl);
    font-weight: 900;
    margin-top: var(--chakra-space-4);
  }
  h4 {
    font-size: var(--chakra-fontSizes-2xl);
    font-weight: 700;
    margin-top: var(--chakra-space-4);
  }
  h5 {
    font-size: var(--chakra-fontSizes-xl);
    font-weight: 900;
    margin-top: var(--chakra-space-4);
  }
  h6 {
    font-size: var(--chakra-fontSizes-xl);
    font-weight: 700;
    margin-top: var(--chakra-space-4);
  }
  ul,
  ol {
    padding-left: var(--chakra-space-4);
    margin-top: var(--chakra-space-4);
    li {
      margin-top: var(--chakra-space-1);
      p {
        margin-top: var(--chakra-space-4);
      }
    }
  }
  p {
    margin-top: var(--chakra-space-4);
  }
  code {
    padding-left: var(--chakra-space-1);
    padding-right: var(--chakra-space-1);
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    border-radius: var(--chakra-radii-sm);
    background-color: var(--chakra-colors-orange-800);
  }
  a {
    color: var(--chakra-colors-orange-400);
  }
}

a.highlight {
  color: var(--chakra-colors-orange-400);
}

iframe {
  background-color: rgba(0, 0, 0, 0);
}
